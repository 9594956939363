import React, { useState } from 'react';
import PropTypes from 'prop-types';

function ResultOverlay({ onClose, data }) {
    const [currentPage, setCurrentPage] = useState(0);

    const nextPage = () => {
        setCurrentPage((currentPage + 1) % 11);
    };

    const previousPage = () => {
        setCurrentPage((currentPage - 1 + 11) % 11);
    };

    // TODO： may need to change the hard-coding style in case of backend change?
    // TODO： Current css is in index.css, may be a good idea to move in this js file? Maybe linked with theme
    const content = {
        1: (
            <div>
                <h2>根数</h2>
                <p>{data.root}</p>
            </div>
        ),
        2: (
            <div>
                <h2>根数特征</h2>
                <p>{data.root_char}</p>
            </div>
        ),
        3: (
            <div>
                <h2>隐藏特性</h2>
                <p>{data.hidden_char}</p>
            </div>
        ),
        4: (
            <div>
                <h2>五行与健康</h2>
                <p>{data.five_ele_health}</p>
            </div>
        ),
        5: (
            <div>
                <h2>五行与个性</h2>
                <p>{data.five_ele_char}</p>
            </div>
        ),
        6: (
            <div>
                <h2>职业与事业的建议</h2>
                <p>{data.five_ele_career}</p>
            </div>
        ),
        7: (
            <div>
                <h2>弱点</h2>
                <p>{data.weakness}</p>
            </div>
        ),
        8: (
            <div>
                <h2>优点</h2>
                <p>{data.talent}</p>
            </div>
        ),
        9: (
            <div>
                <h2>痛点</h2>
                <p>{data.pain}</p>
            </div>
        ),
        10: (
            <div>
                <h2>建议</h2>
                <p>{data.suggestion}</p>
            </div>
        ),
        11: (
            <div>
                <h2>生命的轨迹</h2>
                <p>{data.life_track}</p>
            </div>
        ),
    };

    return (
        <div className="result-overlay">
            <div className="result-content">
                {content[currentPage+1]}
            </div>
            <button onClick={previousPage} className="previous-button">Previous</button>
            <button onClick={nextPage} className="next-button">Next</button>
            <button onClick={onClose} className="close-button">Close</button>
        </div>
    );
}

ResultOverlay.propTypes = {
    onClose: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
};

export default ResultOverlay;
