import { lifeBlueprintUrl } from "./url";
import questions from "./questions";
import { format } from 'react-string-format'

export function getLifeBlueprintUrl(answers) {
    const questionNum = questions.length;
    var nameId, dobId;
    for (let i = 0; i < questionNum; i++) {
        let qn = questions[i];
        if (qn['tag'] === 'name') nameId = qn['index'] - 1;
        if (qn['tag'] === 'dob') dobId = qn['index'] - 1;
    }
    const dobNum = String(answers[dobId]).split('-').join('');
    const url = format('{0}?name={1}&dob={2}', lifeBlueprintUrl, answers[nameId], dobNum);
    return url;
}
