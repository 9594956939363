import React, { useState } from 'react';
import { Button, Input, DatePicker, Radio } from 'antd';
import moment from 'moment';

function Question({ question, onAnswer }) {
    const [answer, setAnswer] = useState('');

    const handleInputChange = (e) => {
        setAnswer(e.target.value);
    };

    const handleDateChange = (date, dateString) => {
        setAnswer(dateString); // Set the answer as the selected date
    };

    const handleRadioChange = (e) => {
        setAnswer(e.target.value);
    };

    const handleSubmit = () => {
        onAnswer(answer);
        setAnswer(''); // Clear the input field
    };

    let inputComponent;

    switch (question.type) {
        case 'date':
            inputComponent = (
                <DatePicker
                    style={{ width: '60%', margin: 'auto', marginLeft: '24px' }}
                    value={answer ? moment(answer, 'YYYY-MM-DD') : null} // Convert answer to moment format
                    onChange={handleDateChange}
                />
            );
            break;
        case 'select':
            inputComponent = (
                <Radio.Group
                    style={{width: '60%'}}
                    buttonStyle={'solid'}
                    optionType ={'button'}
                    onChange={handleRadioChange}
                    value={answer}
                >
                    {question.data.map((option) => (
                        <Radio key={option} value={option}>
                            {option}
                        </Radio>
                    ))}
                </Radio.Group>
            );
            break;
        default:
            inputComponent = (
                <Input
                    style={{ width: '60%', margin: 'auto', marginLeft: '24px' }}
                    type="text"
                    value={answer}
                    onChange={handleInputChange}
                    placeholder="Your answer"
                />
            );
    }

    return (
        <div>
            <h2>问题 {question.index}</h2>
            <div className="col-12" style={{ textAlign : 'center'}}>
                <span>{question.text}</span>
                {inputComponent}
                <Button style={{ margin : '10px' }} type="primary" onClick={handleSubmit}>
                    Submit
                </Button>
            </div>

        </div>
    );
}

export default Question;
