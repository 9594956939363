import React, { useEffect, useState } from 'react';
import {Button, Input, Switch } from 'antd';
import axios from 'axios';

import ResultOverlay from './components/ResultOverlay';
import Question from './components/Question';
import questions from './utils/questions';
import {getLifeBlueprintUrl} from "./utils/api";

function App() {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState<{ [key: number]: string }>({});
  const [isDisabled, setIsDisabled] = useState(true);
  const [data, setData] = useState({});
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('');
  const [contentColor, setContentColor] = useState('');

  // Handler functions
  const handleImageInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBackgroundImage(event.target.value);
  };

  const handleColorInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBackgroundColor(event.target.value);
  };

  const handleContentColorInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setContentColor(event.target.value);
  };

  const handleNextQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const handleAnswer = (userAnswer: string) => {
    setAnswers({ ...answers, [currentQuestionIndex]: userAnswer });
    handleNextQuestion();
  };

  const handleSwitchChange = (checked: boolean) => {
    setIsDisabled(!checked); // Update isDisabled based on switch state
  };

  const handleButtonClick = () => {
    setIsOverlayVisible(true);
  };

  const handleOverlayClose = () => {
    setIsOverlayVisible(false);
  };

  const isQuestionsCompleted = currentQuestionIndex >= questions.length; // Check if all questions have been answered

  // Hook to call backend
  useEffect(() => {
    const fetchData = async () => {
      const questionNum = questions.length, answerNum = Object.keys(answers).length;
      if (questionNum !== answerNum) return;
      const result = await axios(
        getLifeBlueprintUrl(answers),
      );
      console.log(result.data);
      setData(result.data);
    };
    fetchData(); // Call the fetchData function when the component mounts
  }, [answers]);

  // TODO: Modify the style to have better UI
  // TODO: You may break down the return for better structure, it is messy to read now.
  return (
      <div className="App" style={{
        width: '100%',
        height: '100vh',
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: backgroundColor,
        backgroundSize: 'cover',
        color: contentColor
      }}>
        <div>
          <Input placeholder="Input Background Image URL" value={backgroundImage} onChange={handleImageInputChange} />
        </div>
        <div>
          <Input placeholder="Input Background Color Hex Code" value={backgroundColor} onChange={handleColorInputChange} />
        </div>
        <div>
          <Input placeholder="Input Content Color Hex Code" value={contentColor} onChange={handleContentColorInputChange} />
        </div>
        <div style={{ height: '10vh'}}>  </div>
        <h1>人生蓝图 极简版</h1>
        {isQuestionsCompleted ? (
            <div>
              <h2>检查你的信息是否正确：
                <Switch
                    onChange={handleSwitchChange}
                    checkedChildren="正在修改" // Custom text for 'On' state
                    unCheckedChildren="我要修改" // Custom text for 'Off' state
                /> {/* Switch component */}
              </h2>

              {/* Display or process the answers as needed */}
              <ul>
                {Object.keys(answers).map((questionIndex) => (
                    <li key={questionIndex}>
                      <span>{questions[parseInt(questionIndex, 10)].text}</span>
                      <div style={{textAlign : 'center'}}>
                        <Input
                            id={"ans"+String(questionIndex)}
                            disabled={isDisabled}
                            style={{ width: '200px' }}
                            value={answers[parseInt(questionIndex, 10)]}
                            onChange={(e) => {
                              const newAnswers = { ...answers };
                              newAnswers[parseInt(questionIndex, 10)] = e.target.value;
                              setAnswers(newAnswers);
                            }}
                        />
                      </div>
                    </li>
                ))}
              </ul>
              <div style={{ textAlign: 'center' }}>
                <Button type="primary" disabled={!isDisabled} onClick={handleButtonClick}>
                  确认 (请求后端，返回结果)
                </Button>
              </div>
              {isOverlayVisible && data && <ResultOverlay onClose={handleOverlayClose} data={data}/>} {/* Conditionally render the overlay */}
            </div>
        ) : (
            <Question
                question={questions[currentQuestionIndex]}
                onAnswer={handleAnswer}
            />
        )}
      </div>
  );
}

export default App;
