const questions = [
    { index: 1, text: '请告诉我你的姓名拼音', type:'string', tag: 'name' },
    { index: 2, text: '请告诉我你的性别', type:'select', data: ['男', '女'], tag: 'gender' },
    { index: 3, text: '请告诉我你的生日', type:'date', tag: 'dob' },
    { index: 4, text: '你有多胞胎兄弟姐妹吗', type:'select', data: ['有', '没有'], tag: 'hasSibling' },
    { index: 5, text: '你是家里第几个孩子', type:'select', data: ['1', '2', '3+'], tag: 'ageOrder' },
    // Add more questions as needed
];

export default questions;
